<template>
  <div class="person_center">
    <header>
        <router-link class="Jump" :to="phone ? '' : '/account/login'">{{ phone ? phone : '立即登录' }}</router-link>
    </header>
    <div class="list mt-10">
      <van-cell is-link title="我的订单" to="/order" />
      <van-cell is-link title="收藏" to="/collection" />
      <van-cell is-link title="足迹" to="/footprint" />
      <van-cell is-link title="优惠劵" to="/coupon" />
      <van-cell is-link title="我的地址" to="/address" />
      <van-cell is-link title="设置" to="/my/set" />
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import { mapState } from "vuex";
export default {
  components: {
    Footer,
  },
  computed: {
    ...mapState(["phone"]),
  },
};
</script>
<style lang="less" scoped>
.person_center {
  header {
    background: url("http://fresh.huruqing.cn/img/bg2.78d35cdc.png") no-repeat;
    background-size: 100%;
    height: 200px;
    .Jump {
      position: relative;
      top: 20px;
      left: 20px;
      font-size: 15px;
      color: #fff;
    }
  }
  .list {
    border: 1px solid #fff;
  }
}
</style>
